import { createStore } from 'vuex'

export default createStore({
  state: {
    token: window.localStorage.getItem('merchantsSYS_token'),
    user: JSON.parse(window.localStorage.getItem('merchantsSYS_user')|| '{}'),

  },
  mutations: {
    UPDATETOKEN(state, [token, user]) {
      state.token = token
      state.user = user
      window.localStorage.setItem('merchantsSYS_token', token)
      window.localStorage.setItem('merchantsSYS_user', JSON.stringify(user))

    },
    LOGINOUT (state) {
      state.token = ''
      state.user = null
      window.localStorage.setItem('merchantsSYS_token', '')
      window.localStorage.setItem('merchantsSYS_user', '{}')
    },
    UPDATEUSRE(state, user){
      state.user = user
      window.localStorage.setItem('merchantsSYS_user', JSON.stringify(user))
    }
  }
})