import './YcomCss.css'
window.$Ycom = {
  $emit: function (name, e) {
    document.body.dispatchEvent(new CustomEvent(name, { detail: e }))
  },
  $on: function (name, fun) {
    document.body.addEventListener(name, fun)
  },
  $c: function (name, fun) {
    document.body.removeEventListener(name, fun, false)
  },
  $con: function (name, fun) {
    document.body.addEventListener(name, async function eventFun (e) {
      await document.body.removeEventListener(name, eventFun)
      fun(e.detail)
    })
  }
}
