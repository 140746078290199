import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: '/login',
    meta: { title: '登录', pass: true }
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '-登录', pass: true },
    component: () => import('../views/sysLogin.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { title: '-管理', pass: false },
    component: () => import('../views/empView.vue'),
    children:[
    {
      path: '',
      redirect: '/admin/echarts',
      name: 'adminRoot',
      meta: { title: '-管理', pass: false },
    },
    { 
      path: 'user',
      name: 'user',
      meta: { title: '-用户', pass: false },
      component: () => import('../views/temp/user/adminUser.vue')
    },
    { 
      path: 'comUser',
      name: 'comUser',
      meta: { title: '-用户', pass: false },
      component: () => import('../views/temp/user/comUser.vue')
    },
    {
      path: 'category',
      name: 'category',
      meta: { title: '-分类', pass: false },
      component: () => import('../views/information/categoryView.vue')
    },
    
    {
      path: 'area',
      name: 'area',
      meta: { title: '-地区', pass: false },
      component: () => import('../views/information/areaView.vue')
    },
    {
      path: 'echarts',
      name: 'echarts',
      meta: { title: '-统计', pass: false },
      component: () => import('../views/echarts/EchartsView.vue')
    },
    {
      path: 'log',
      name: 'log',
      meta: { title: '-日志', pass: false },
      component: () => import('../views/log/logView.vue')
    },
    { 
      path: 'set',
      name: 'set',
      meta: { title: '-设置', pass: false },
      component: () => import('../views/Element Plus/ElementView.vue')
    },
    { 
      path: 'item/:id',
      name: 'item',
      meta: { title: '-项目地块', pass: false },
      component: () => import('../views/information/itemView.vue')
    },
    
    { 
      path: 'news/:type',
      name: 'news',
      meta: { title: '-新闻动态', pass: false },
      component: () => import('../views/News/noticeView.vue')
    },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//   router.beforeEach((to, from, next) => {
//     // if (!to.meta.title) {
//     //   (window).$Ycom.$emit('y-com-newtip-addone', ['页面不存在', 3000])
//     //   next(from.fullPath)
//     // }
//     const token = "";
//     if (token) {
//       next()
//     } else {
//       next('/')
//     }
//   })

router.beforeEach ((to, from, next) => {
  
  if(to.name != "login" && !store.state.token) {
    next('/')
    // 
  }
  next()
})
router.afterEach((to) => {
  document.title = '沾益招商' + to.meta.title + ''
})


export default router
