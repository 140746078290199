<template>
  <div>
    <YcomControl></YcomControl>
    <YcomNewtip></YcomNewtip>
    <YcomLoading></YcomLoading>
    <!-- <ElementView></ElementView> -->
    <!-- <div theme> 1</div>
    <div theme1> 1</div>
    <div theme2> 1</div>
    <div class="main"> 1</div> -->
    <router-view></router-view>
  </div>
</template>

<script>
import { reactive } from 'vue';
// import ElementView from './views/Element Plus/ElementView.vue';

import YcomControl from './y-com/YcomControl/YcomControl.vue'
import YcomNewtip from './y-com/YcomNewtip/YcomNewtip.vue'
import YcomLoading from './y-com/Ycomloading/Ycomloading.vue'
export default {
  components:{ YcomControl,YcomNewtip, YcomLoading},
  setup() {

    const data = reactive({
      msg: "hello"
    });

    return {
      data
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
}
.el-menu-item,.el-sub-menu__title{
  border: 8px solid rgba(255, 255, 255, 0) !important;

  /* margin: 10px; */
  border-radius: 5px;
  box-sizing: border-box !important;
  margin: 2px  ;


}
.el-sub-menu__title, .el-menu-item{
  /* border: 8px solid rgba(255, 255, 255, 0) !important; */
  margin: 0  auto !important;
  width: 90% !important;
  /* border: 10rpx solid ; */
  /* position: relative; */
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0.175, 0.985, 0.32, 1.85), background-color 0.25s;

  /* background-color: red; */

}
.el-sub-menu.is-active > .el-sub-menu__title{
  color: var(--y-com--theme) !important;
  background: var(--w-e-toolbar-bg-color2) !important;
  /* animation: textAction .5s; */

}
@keyframes textAction {
  50% {
    padding-left: 80px;
    
  }
}
.el-sub-menu.is-active > .el-sub-menu__title > .el-icon{
  animation: activeone .5s;
  /* width: 100px; */
}
@keyframes activeone {
  50% {
    transform: rotate(45deg) scale(1.7);
  }
}
.el-menu--inline{
  background: var(--y-mode--theme) !important;
}
.el-menu-item:hover,.el-sub-menu__title:hover {
  width: 95% !important;
  /* background: var(--y-com--theme) !important;
  color: white; */
  /* margin: 2px auto !important; */
  
  background: var(--y-mode--theme1) !important;
}
.el-sub-menu .el-sub-menu__icon-arrow{
  right: -.8vw !important;
  width: 70% !important;
  /* box-sizing: border-box; */
}
/* .is-active:deep() {
  color: var(--y-com--theme) !important;
  background: var(--w-e-toolbar-bg-color) !important;
}
.el-menu-item:deep(),
.el-sub-menu:deep(),
.el-menu-item-group__title:deep() {
  background: var(--y-com-themeborderColor2);
} */
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
.main{
  color: var(--y-com--theme);
}
*{
  margin: 0;
  padding: 0;
  --el-color-primary:var(--y-com--theme) !important;
  --el-button-hover-border-color:var(--y-com--theme) !important;
  --el-button-hover-bg-color:var(--y-com--theme) !important;
  --el-button-hover-text-color:white !important;
  /* color: var(--y-com-fontColor1);
  --y-com--theme: var(--y-com--theme0);
  --y-com-themeborderColor: var(--y-com-fontColor1);
  --y-com-themeborderColor2: var(--y-com-fontColor2);
  --y-com-themetextColor: var(--y-com-fontColor1);
  --w-e-textarea-bg-color: #f5f5f5;
  --w-e-toolbar-bg-color: #efefef; */
}
.el-button:hover {
  background-color: gray;
  opacity: .5;
}

</style>
