import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import store from './store/index.js'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import './y-com/Ycomjs.js'


createApp(App).use(router).use(store).use(ElementPlus, { locale: zhCn }).mount('#app')
